/*.left-column{
  border-right: 1px solid black;
}

.right-column{
  border-left: 1px solid black;
}
*/
.options-button{
    width: 100%;
    padding: 10px 20px;
    border: 0px;
    border-radius: 10px;
    margin-bottom: 5px;
    text-align: start;
  }
  
  .options-button:hover{
    background-color: lightgray;
  }
  /*.back-view-button{
    border-radius: 10px;
    border: 0px;
    padding: 10px;
  }*/

  .color-type-content{
    display: flex;
    flex-wrap: wrap;
  }
  
  
  .confirmation-button{
    border: 0px;
    margin-right: 20px;
    padding: 10px;
    border-radius: 10px;
  }
  
  .confirmation-button:hover{
    background-color: gray;
  }
  
  .design-screen .item img{
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
  }
  
  .design-screen .item{
    padding: 12px;
    text-align: center;
  }
  
  .design-screen .item:hover{
    background-color: #1291a8;
    cursor: pointer;
  }
  
  .design-screen .item .window-option-text{
    text-align: center;
    color: #0b1a48;
  }
  
  .color-screen .item img{
    width: 4rem;
    height: 4rem;
    margin-bottom: 12px;
    border-radius: 50%;
  }
  
  .color-screen .item{
    padding: 12px;
    text-align: center;
    width: 6rem;
  }
  
  .color-screen .item:hover{
    background-color: #1291a8;
  }
  
  
  .color-screen .item .window-option-text{
    font-size: 12px;
    text-align: center;
    color: #0b1a48;
  }

  .glazzing-screen .item img{
    width: 4rem;
    height: 4rem;
    margin-bottom: 12px;
    border-radius: 50%;
  }
  
  .glazzing-screen .item{
    padding: 12px;
    text-align: center;
    width: 6rem;
  }
  
  .glazzing-screen .item:hover{
    background-color: #1291a8;
  }
  
  .glazzing-screen .item .window-option-text{
    font-size: 12px;
    text-align: center;
    color: #0b1a48;
  }
    
  .card-screen .item img{
    width: 255px;
    height: 255px;
    border-radius: 10px;
  }

  .card-screen .item .window-option-image{
    text-align: center;
  }

  .select-component{
    max-height: 80vh;
    height: 80vh;
  }

  .custom-card:hover{
    background-color: white;
    box-shadow: 0px 0px 5px;
  }
  
  .custom-card .text-center{
    height: 100px;
  }
  
  .card-screen .item .window-option-text{
    text-align: center;
    text-decoration: none;
    color: #0b1a48;
  }
  .card-screen a{
    text-decoration: none;
  }
  
  .header{
    display: flex;
    box-shadow: 5px -3px 12px #888888;
    z-index: 9999999999999999999999;
    height: 118px;
  }
  
  .back-button{
    margin-right: 20px;
    padding: 5px 10px;
    margin-left: 10px;
    margin-top: 10px;
    border: 0px;
    color: black;
    background-color: white;
    border: 2px solid red;
    border-radius: 10px;
    position: fixed;
    z-index: 999999999999;
  }
  
  .back-button svg{
    background-color: white;
    color: black;
    font-size: 26px;
  }
  
  .cart-data-table tbody td{
    padding: 14px;
  }
  
  .popup-content {
    width: 80% !important;
    height: 80% !important;
    border-radius: 15px;
  }
  .model-popup-with-form-overlay .popup-content {
    width: 20% !important;
    height: auto !important;
  }
  
  .close{
    position: absolute;
    display: block;
    line-height: 28px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border: 1px solid #cfcece;
    border-radius: 18px;
    padding-bottom: 5px;
  }

  .full-height{
    height: 80vmin;
  }
  
  
  /*#1291a8*/
  
  .App .bg-danger{
    background-color: #1291a8 !important;
  }
  
  .selected {
    background-color: #1291a8;
  }
  
  @media print {
    .print-content {
      display: block; /* Display the content only for printing */
    }
  } 
  .glass-thickness-area{
    margin-bottom: 50px;
  }
  
  .glass-thickness-area-child{
    margin-right:20px;
  }
  .glass-thickness-area-child input{
    margin-right:5px;
  }
  .error-message{
    color:red;
  }
  
  body, h1, h2, h3, h4, h5, h6, p, a, span {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
  
  .title {
      font-size: 42px;
      line-height: 42px;
      color: #1291a8;
      text-align: center;
  }
  .blue-text {
    color: #1291a8;
  }
  .contact-link {
    text-decoration: none;
    color:black;
  }
  .button-bottom {
    position: fixed;
    bottom: 80px;
    right: 30px;
  }
  /* .footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: white;
  } */
  /* .footer-content {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
    box-shadow: 5px -3px 12px #888888;
  } */
  .footer-web-link {
    text-decoration: none;
    color: #ff9027;
  }
  .custom-card {
    border-radius: 10px;
    height: 400px;
  }
  .float-right {
    float: right;
  }
  .gf-button {
    background-color: #1291A8;
    color: white;
    border-color: transparent;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    padding: 16px 40px 16px 40px;
    border-width: 0px;
    border-radius: 8px;
  }
  .gf-button:hover{
    background-color: #37ad23;
  }
  
  .cart-page-sidebar{
    position: relative;
    bottom: 0;
    right: 0;
    display: table-cell;
    min-height: calc(100vh - 160px);
  }
  .header-center{
    margin-top: 20px;
  }
  .select-page-row{
    margin-top: 20%;
  }
  .product-remove-button{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 900px) {
    .popup-content {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0px;
    }
    .model-popup-with-form-overlay .popup-content {
      width: 40% !important;
      height: 80% !important;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .popup-content {
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .cart-page-sidebar{
      position: inherit;
    }
    .footer-content h5{
      font-size: 0.7rem;
    }
  }
  .table-in-table{
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .table-parent{
    padding: 0px !important;
  }
  .table-in-table tbody > tr:last-child > td {
    border-bottom: 0;
  }.table-in-table tbody > tr > td:last-child {
    border-left: 1px solid #dee2e6;
  }
  .color-div-parent{
    width: 100%;
  }
  
  .onlyPrint h1{
    text-align: left;
  }
  .special-desktop-margin{
    margin: 5rem;
  }
  .cart-page-bottom-margin{
    margin-bottom: 52px;
  }
  .cart-link-header-button{
    color: white;
    background-color: #1291A8;
    padding: 10px 15px;
    border-radius: 10px;
  }
  .cart-button-column{
    margin-top: 1rem !important;
  }
  .empty-result{
    height: 100%;
    display: flex;
    align-content: space-around;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  
  @media screen {
    .onlyPrint {
        display: none !important;
    }
  }
  
  
  @media print {
    @page{
      margin:0px;
    }
    .special-desktop-margin{
      margin: 0;
    }
    body{
      visibility: hidden;
    }
    #print-content{
      visibility: visible;
      position: relative !important;
      left: 0px !important;
      top: 0px !important;
      height: auto;
      margin-top: -118px;
      display: inline;
    }
    .card-header,.header, #backButton, .footer, .hide-for-print{
      display: none;
    }
  }
  
  .footer{
    color:#4C4D52;
  }
  
  .footer .blue-heading{
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    color:#1291a8;
  }
  
  .footer-content{
    padding-top: 110px;
    padding-bottom: 12rem;
  }
  
  .footer ul{
    list-style: none;
  }
  
  .footer .container{
    border-bottom: 1px solid black;
  }

  .footer .container .row{
    margin-bottom: 30px;
  }

  .footer li a{
    text-decoration: none;
    color: #4C4D52;
  }

  .footer ul{
    padding: 0px;
    line-height: 2.5;
  }


  .first-column .description{
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .second-column {
    padding-left: 20px;
  }

  .address-parent{
    display: flex;
  }

  .address-icon {
    padding-right: 20px;
  }

  .first-column svg{
    margin: 0px 5px;
  }

  .first-column a{
    color: black;
  }

  .end-credits{
    height: 69px;
  }

  .image-column img{
    width: 300px;
  }


  @media (max-width: 767px){
  .first-column,.second-column,.third-column {
    text-align: center;
  }
  .footer .blue-heading{
    font-size: 17px;
  }
  .second-column{
    margin-top: 30px !important;
  }
  .footer-content{
    padding-bottom: 0px;
  }
  .header .title-column{
    display: none;
  }
  .header .contact-info-column{
    order: 2;
  }
  .image-column img{
    width: 200px;
  }

}
.parent-div{
  display: flex;
  flex-wrap: wrap;
}

canvas{
  width: 400px;
  height: 400px;
}

.parent-for-glazing-popup-content{
  overflow-y: overlay;
  overflow-x: hidden;
}
.parent-for-color-popup-content{
  overflow-y: overlay;
  overflow-x: hidden;
}

.responsive-message-block{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  background-color: white;
  margin: auto;
}

.responsive-message-block h1{
  color:black;
}

@media only screen and (max-width: 933px) and (orientation: landscape) {

  .title{
    font-size: 24px;
  }
  .image-column img{
    width: 200px;
  }
  /* .cart-button-column{
    margin-top: -2rem !important;
  } */
  .button-bottom{
    right:-3rem;
    transform: rotate(-90deg);
    bottom: 35%;
  }
  .header{
    height: auto;
  }
  .card-screen .item img{
    width: 200px;
    height: 200px;
  }
  .custom-card{
    height: auto;
  }
  .design-page-title{
    font-size: 20px;
  }

  
  .model-popup-canvas{
    width: 300px;
    height: 300px;
  }

  .genral-string{
    font-size: 0.8rem;
  }

  /* size model popup */
  

  /* color model popup */
  .color-screen .item img{
    width: 2rem;
    height: 2rem;
  }

  .glazzing-screen .item img{
    width: 2rem;
    height: 2rem;
  }

  .add-scroller-for-mobile{
    overflow-y: scroll;
    height: 300px;
  }
 
}